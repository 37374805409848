<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <v-btn
        color="secondary"
        class="mr-3 mb-1"
        text
        @click="$router.go(-1)"
      >
        <v-icon
          left
          dark
        >
          mdi-undo-variant
        </v-icon>
        ย้อนกลับ
      </v-btn>

      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ภาพรวมการใช้ยาและเวชภัณฑ์</h4>
      </div>
      <v-card-title class="py-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="2">
            <v-select
              :items="itemsPerTimeFilter"
              v-model="select_filter_time"
              item-text="name"
              item-value="value"
              label="รายการใช้ยา"
              placeholder="เลือกรายการใช้ยา"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsMonthsFilter"
              v-model="select_filter_month"
              item-text="name"
              item-value="value"
              label="เดือน"
              placeholder="เลืือกเดือน"
              single-line
              hide-details
              :disabled="this.select_filter_time === 'day'"
              :readonly="this.select_filter_time === 'day'"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="3" class="d-flex flex-row align-center">
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="this.select_filter_time === 'month'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วันที่"
                  placeholder="เลือกวันที่"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="select_filter_dates"
                no-title
                :disabled="this.select_filter_time === 'month'"
                :readonly="this.select_filter_time === 'month'"
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-center justify-end px-3 pb-3">
            <v-btn
              :loading="loaddata"
              :disabled="loaddata"
              color="blue-grey"
              class="white--text"
              @click="searchData"
            >
              <v-icon
                left
                dark
              >
                mdi-magnify
              </v-icon>
              แสดงข้อมูล
            </v-btn>
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
        :footer-props="{
          'items-per-page-text':'การแสดงข้อมูล'
        }"
      >
      <template v-slot:top>
        <div class="d-flex flex-row">
          <v-spacer/>
          <v-btn
            :loading="loader"
            :disabled="loader"
            color="secondary"
            class="my-3 white--text"
            @click="goExport"
          >
            <v-icon
              left
              dark
            >
              mdi-file-excel
            </v-icon>
            นำออกเอกสาร
          </v-btn>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">{{item.product_code}}</td>
          <td class="text-start">{{item.product_name}}</td>
          <td class="text-end">{{formatNumber(item.product_usedinmonth)}}</td>
          <td class="text-end">{{formatNumber(item.product_remain)}}</td>
          <td class="text-center">{{item.product_unit}}</td>
          <template v-for="(element, index) in item.dates">
            <td :key="index" class="text-end">{{formatNumber(element.used)}}</td>
          </template>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="primary lighten-1 white--text">
          ข้อมูลการตัดสต๊อก
        </v-card-title>

        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">รหัส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>{{dataDialog.product_code}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>{{dataDialog.product_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">ใช้ไป:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>{{dataDialog.product_used}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">หัตถการ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>TEST</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDialog"
          >
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      select_filter_time: 'day',
      select_filter_month: '',
      select_filter_dates: new Date().toISOString().substr(0, 10),
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      itemsPerTimeFilter: [{
        name: 'รายวัน',
        value: 'day'
      }, {
        name: 'รายเดือน',
        value: 'month'
      }],
      itemsMonthsFilter: [{
        name: 'มกราคม',
        value: 'january'
      }, {
        name: 'กุมภาพันธ์',
        value: 'february'
      }, {
        name: 'มีนาคม',
        value: 'march'
      }, {
        name: 'เมษายน',
        value: 'april'
      }, {
        name: 'พฤษภาคม',
        value: 'may'
      }, {
        name: 'มิถุนายน',
        value: 'june'
      }, {
        name: 'กรกฎาคม',
        value: 'july'
      }, {
        name: 'สิงหาคม',
        value: 'august'
      }, {
        name: 'กันยายน',
        value: 'september'
      }, {
        name: 'ตุลาคม',
        value: 'october'
      }, {
        name: 'พฤศจิกายน',
        value: 'november'
      }, {
        name: 'ธันวาคม',
        value: 'december'
      }],
      headers: [],
      data: [],
      menuFilterDate: false
    }
  },
  computed: {
    computedFilterDateFormatted () {
      const self = this
      if (self.select_filter_time === 'day') return `${self.select_filter_dates ? moment(self.select_filter_dates).add(543, 'year').format('DD/MM/YYYY') : moment().add(543, 'year').format('DD/MM/YYYY')}`
      else return ''
    }
  },
  watch: {
    select_filter_search () {
      const self = this
      if (self.select_filter_search === 'ทั้งหมด') {
        self.filter_search = ''
      } else {
        self.filter_search = self.select_filter_search
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.data = []
      self.fetchCategory()
    },
    async fetchCategory () {
      const self = this
      self.fetchData()
    },
    async fetchData () {
      const self = this
      self.loaddata = true
      var temp = []
      var tempHeader = []
      tempHeader = [
        {
          text: 'รหัส',
          align: 'center',
          value: 'product_code',
          sortable: false
        }, {
          text: 'ชื่อ',
          align: 'center',
          value: 'product_name',
          sortable: false
        }, {
          text: 'ใช้ไปทั้งเดือน',
          align: 'center',
          value: 'product_usedinmonth',
          sortable: false
        }, {
          text: 'คงเหลือ',
          align: 'center',
          value: 'product_remain',
          sortable: false
        }, {
          text: 'หน่วยนับ',
          align: 'center',
          value: 'product_unit',
          sortable: false
        }
      ]
      const dates = [{
        _id: '0',
        date: moment(),
        used: 3
      }, {
        _id: '1',
        date: moment(),
        used: 5
      }, {
        _id: '2',
        date: moment(),
        used: 8
      }, {
        _id: '3',
        date: moment(),
        used: 3
      }, {
        _id: '4',
        date: moment(),
        used: 10
      }, {
        _id: '5',
        date: moment(),
        used: 15
      }]
      dates.map(el => {
        tempHeader.push({
          text: moment(el.date).add(543, 'year').format('DD/MM/YYYY'),
          align: 'center',
          value: el.date,
          sortable: false
        })
      })
      self.headers = tempHeader
      temp = [{
        _id: '000000',
        product_no: 1,
        product_category: 'ยา',
        product_code: 'M100001',
        product_name: 'Botox กราม (0.7)',
        product_usedinmonth: 20,
        product_group_name: 'Botox',
        product_type: 'หน้า',
        product_costprice: 1500,
        product_sellprice: 2999,
        product_used: 50,
        product_remain: 50,
        product_remain_alert: 50,
        product_unit: 'คู่',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001',
        dates: [{
          _id: '0',
          date: moment(),
          used: 3
        }, {
          _id: '1',
          date: moment(),
          used: 5
        }, {
          _id: '2',
          date: moment(),
          used: 8
        }, {
          _id: '3',
          date: moment(),
          used: 3
        }, {
          _id: '4',
          date: moment(),
          used: 10
        }, {
          _id: '5',
          date: moment(),
          used: 15
        }]
      }, {
        _id: '000001',
        product_no: 2,
        product_category: 'ยา',
        product_code: 'M100002',
        product_name: 'Botox Allergan (ขวด)',
        product_usedinmonth: 356,
        product_group_name: 'Botox',
        product_type: 'กราม',
        product_costprice: 1500,
        product_sellprice: 2999,
        product_used: 0,
        product_remain: 0,
        product_remain_alert: 50,
        product_unit: 'ขวด',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001',
        dates: [{
          _id: '0',
          date: moment(),
          used: 3
        }, {
          _id: '1',
          date: moment(),
          used: 5
        }, {
          _id: '2',
          date: moment(),
          used: 8
        }, {
          _id: '3',
          date: moment(),
          used: 3
        }, {
          _id: '4',
          date: moment(),
          used: 10
        }, {
          _id: '5',
          date: moment(),
          used: 15
        }]
      }, {
        _id: '000002',
        product_no: 3,
        product_category: 'ยา',
        product_code: 'M100003',
        product_name: 'Botox กราม (0.7)',
        product_usedinmonth: 300,
        product_group_name: 'Botox',
        product_type: 'หน้า',
        product_costprice: 800,
        product_sellprice: 1999,
        product_used: 200,
        product_remain: 200,
        product_remain_alert: 50,
        product_unit: 'CC',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001',
        dates: [{
          _id: '0',
          date: moment(),
          used: 3
        }, {
          _id: '1',
          date: moment(),
          used: 5
        }, {
          _id: '2',
          date: moment(),
          used: 8
        }, {
          _id: '3',
          date: moment(),
          used: 3
        }, {
          _id: '4',
          date: moment(),
          used: 10
        }, {
          _id: '5',
          date: moment(),
          used: 15
        }]
      }, {
        _id: '000003',
        product_no: 4,
        product_category: 'ยา',
        product_code: 'M100004',
        product_name: 'Botox กราม (0.7)',
        product_usedinmonth: 200,
        product_group_name: 'Botox',
        product_type: 'หน้า',
        product_costprice: 700,
        product_sellprice: 1999,
        product_used: 166,
        product_remain: 166,
        product_remain_alert: 50,
        product_unit: 'เส้น',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001',
        dates: [{
          _id: '0',
          date: moment(),
          used: 3
        }, {
          _id: '1',
          date: moment(),
          used: 5
        }, {
          _id: '2',
          date: moment(),
          used: 8
        }, {
          _id: '3',
          date: moment(),
          used: 3
        }, {
          _id: '4',
          date: moment(),
          used: 10
        }, {
          _id: '5',
          date: moment(),
          used: 15
        }]
      }, {
        _id: '000004',
        product_no: 5,
        product_category: 'ยา',
        product_code: 'M100005',
        product_name: 'Filler (cc)',
        product_usedinmonth: 250,
        product_group_name: 'Filler',
        product_type: 'หน้า',
        product_costprice: 2500,
        product_sellprice: 4500,
        product_used: 90,
        product_remain: 90,
        product_remain_alert: 50,
        product_unit: 'CC',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001',
        dates: [{
          _id: '0',
          date: moment(),
          used: 3
        }, {
          _id: '1',
          date: moment(),
          used: 5
        }, {
          _id: '2',
          date: moment(),
          used: 8
        }, {
          _id: '3',
          date: moment(),
          used: 3
        }, {
          _id: '4',
          date: moment(),
          used: 10
        }, {
          _id: '5',
          date: moment(),
          used: 15
        }]
      }, {
        _id: '000005',
        product_no: 6,
        product_category: 'เวชภัณฑ์',
        product_code: 'E100001',
        product_name: 'Tube PRP (หลอด)',
        product_usedinmonth: 200,
        product_group_name: 'PRP',
        product_type: 'PRP หน้า',
        product_costprice: 20,
        product_sellprice: 0,
        product_used: 2,
        product_remain: 400,
        product_remain_alert: 100,
        product_unit: 'หลอด',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001',
        dates: [{
          _id: '0',
          date: moment(),
          used: 3
        }, {
          _id: '1',
          date: moment(),
          used: 5
        }, {
          _id: '2',
          date: moment(),
          used: 8
        }, {
          _id: '3',
          date: moment(),
          used: 3
        }, {
          _id: '4',
          date: moment(),
          used: 10
        }, {
          _id: '5',
          date: moment(),
          used: 15
        }]
      }]
      setTimeout(() => {
        self.data = temp
        self.loaddata = false
      }, 200)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    searchData () {
      const self = this
      self.initData()
    },
    computedUsedDates (items) {
      return `${moment(items[0]).add(543, 'year').format('DD/MM/YYYY')} ถึง ${moment(items[1]).add(543, 'year').format('DD/MM/YYYY')}`
    },
    formatNumber (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    viewItem (item) {
      const self = this
      self.showDialog = true
      self.dataDialog = item
    },
    closeDialog () {
      const self = this
      self.showDialog = false
      self.dataDialog = {}
    },
    goOverview () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
        self.$router.push({ name: 'OverviewDrugAndMedicalWarehouseScreen' })
      }, 200)
    },
    goExport () {
      console.log('Export File')
    }
  }
}
</script>
